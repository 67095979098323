<template>
  <dev-only>
    <v-card
      variant="flat"
      density="comfortable"
      border
      v-bind="$attrs"
    >
      <v-toolbar
        density="compact"
        :color="!isDebugging ? 'transparent' : ''"
        :theme="!isDebugging ? '' : 'dark'"
        @click="debug = !debug"
      >
        <span class="text-caption font-weight-bold text-medium-emphasis ml-4">{{ safeTitle }}</span>

        <v-spacer />

        <v-slide-x-reverse-transition>
          <v-chip
            v-if="error?.length || !isEmpty(error)"
            class="mtf-has-errors"
            color="error"
            label
            :variant="isDebugging ? 'flat' : 'tonal'"
          >
            <v-icon
              start
              icon="mdi-alert-circle-outline"
            />
            Contains Errors
          </v-chip>
        </v-slide-x-reverse-transition>

        <v-icon
          class="mx-4"
          :icon="isDebugging ? 'mdi-chevron-up' : 'mdi-chevron-down'"
        />
      </v-toolbar>

      <v-expand-transition>
        <v-list
          v-if="isDebugging"
          density="compact"
          :lines="false"
          disabled
          class="pt-0"
        >
          <template
            v-for="(item, i) in items"
            :key="i"
          >
            <v-divider v-if="item.type == 'divider'" />

            <v-list-item
              v-if="item.type == 'subheader'"
              :subtitle="item.title"
              v-bind="item.props"
              class="py-2"
            />

            <v-list-item
              v-if="(!item.type || item.type === 'item') && !item.hide"
              :value="item"
              :color="item.props?.color || 'info'"
              v-bind="item.props"
              :title="item.title"
              :subtitle="item.subtitle"
            >
              <!-- <v-list-item-title
                v-if="item.title"
                v-text="item.title"
              />
              <v-list-item-subtitle
                v-if="item.subtitle"
                v-text="item.subtitle"
              /> -->
              <code v-if="item.data">
                <pre>{{ item.data }}</pre>
              </code>
            </v-list-item>
          </template>
        </v-list>
      </v-expand-transition>
    </v-card>
  </dev-only>
</template>

<script>
import { isEmpty } from 'lodash-es';

export default defineComponent({
  name: 'MtfDebug',
  inheritAttrs: true,
  customOptions: {},
  props: {
    // icon: String,
    title: String,
    // description: String,
    // note: String,
    loading: Boolean,
    processing: Boolean,
    state: Object,
    model: Object,
    meta: Object,
    error: [String, Object]
  },
  setup() {
    return {
      isEmpty
    };
  },
  data() {
    return {
      debug: false
    };
  },
  computed: {
    safeTitle() {
      return this.title || 'Debug';
    },
    items() {
      const data = [];

      if (this.error?.length || !isEmpty(this.error)) {
        data.push(
          {
            type: 'subheader',
            title: 'Contains Errors',
            props: {
              prependIcon: 'mdi-alert-circle-outline',
              color: 'error',
              active: true
            }
          },
          {
            data: this.error,
            props: {
              color: 'error',
              active: true
            }
          },
          { type: 'divider' }
        );
      }

      data.push(
        {
          type: 'subheader',
          title: 'Activity',
          props: {
            prependIcon: 'mdi-bell-ring'
          }
        },

        {
          data: {
            isLoading: this.loading,
            isProcessing: this.processing
          }
        }
      );

      if (this.state) {
        data.push(
          { type: 'divider' },
          {
            type: 'subheader',
            title: 'State',
            props: {
              prependIcon: 'mdi-state-machine'
            }
          },
          {
            data: this.state?.toStrings()
          }
        );
      }

      if (this.model) {
        data.push(
          { type: 'divider' },
          {
            type: 'subheader',
            title: 'Model',
            props: {
              prependIcon: 'mdi-database'
            }
          },
          {
            data: this.model
          }
        );
      }

      if (this.meta) {
        data.push(
          { type: 'divider' },
          {
            type: 'subheader',
            title: 'Meta',
            props: {
              prependIcon: 'mdi-information'
            }
          },
          {
            data: this.meta
          }
        );
      }

      return data;
    },
    isDebugging() {
      return this.debug || this.error?.length || !isEmpty(this.error);
    }
  }
});
</script>
